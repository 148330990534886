import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <article className="wrapper">
      <div><h1 className="title">Txokeer</h1></div>
      <div><p>Página en construcción</p></div>
    </article>
  );
};

export default LandingPage;
